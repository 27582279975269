import ApiRequest from "../utils/apiRequest"

export default class User {
    static login = async (body) => {
        return await ApiRequest.set("v1/user/login", "POST", body)
    }

    static loginShort = async (body) => {
        return await ApiRequest.set("v1/user/loginshort", "POST", body)
    }

    static getAll = async () => {
        return await ApiRequest.set("v1/users", "GET")
    }

    static get10latest = async () => {
        return await ApiRequest.set("v1/users/10_latest", "GET")
    }

    static getById = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "GET")
    }

    static getByUUID = async (uuid) => {
        return await ApiRequest.set("v1/user/uuid/" + uuid, "GET")
    }

    static getAllNoRestrictions = async () => {
        return await ApiRequest.set("v1/users/complete", "GET")
    }

    static scanAction = async (uuid) => {
        return await ApiRequest.set("v1/qr/scan/" + uuid, "GET")
    }

    static create = async (body) => {
        return await ApiRequest.set("v1/user", "POST", body)
    }

    static createBatch = async (body) => {
        return await ApiRequest.set("v1/user/create_batch", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/user/" + id, "PUT", body)
    }
    static password_reset = async (id, body) => {
        return await ApiRequest.set("v1/user/" + id + "/password_reset", "PATCH", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "DELETE")
    }

    static restore = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "PATCH")
    }

    static getListAge = [
        {
            'label': '18 - 25',
            'value': 18
        },
        {
            'label': '26 - 35',
            'value': 26
        },
        {
            'label': '36 - 40',
            'value': 36
        },
        {
            'label': '>41',
            'value': 41
        }
    ]


    static getListIndustry = [
        {
            'label': 'Government',
            'value': 'Government'
        }, {
            'label': 'Manufacturing',
            'value': 'Manufacturing'
        }, {
            'label': 'Bank',
            'value': 'Bank'
        }, {
            'label': 'Oil and Gas',
            'value': 'Oil and Gas'
        }, {
            'label': 'Insurance',
            'value': 'Insurance'
        }, {
            'label': 'E-Commerce',
            'value': 'E-Commerce'
        }, {
            'label': 'Healthcare',
            'value': 'Healthcare'
        }, {
            'label': 'Media',
            'value': 'Media'
        }, {
            'label': 'Telecomunication',
            'value': 'Telecomunication'
        }, {
            'label': 'Academic',
            'value': 'Academic'
        }, {
            'label': 'Construction',
            'value': 'Construction'
        }, {
            'label': 'Logistic',
            'value': 'Logistic'
        }, {
            'label': 'FMCG',
            'value': 'FMCG'
        }, {
            'label': 'Aviation',
            'value': 'Aviation'
        }, {
            'label': 'Community',
            'value': 'Community'
        },
    ]

    static getListRole = [
        {
            'label': 'VISITOR',
            'value': 'VISITOR'
        }, {
            'label': 'ASSOCIATE',
            'value': 'ASSOCIATE'
        }, {
            'label': 'ADMIN',
            'value': 'ADMIN'
        }, {
            'label': 'CREW',
            'value': 'CREW'
        }, {
            'label': 'VENDOR',
            'value': 'VENDOR'
        }, {
            'label': 'EXHIBITOR',
            'value': 'EXHIBITOR'
        }, {
            'label': 'SPEAKER',
            'value': 'SPEAKER'
        }, {
            'label': 'AI CHALLENGE',
            'value': 'AI_CHALLENGE'
        }, {
            'label': 'DELEGATES',
            'value': 'DELEGATES'
        },{
            'label': 'MEDIA',
            'value': 'MEDIA'
        },{
            'label': 'MODERATOR',
            'value': 'MODERATOR'
        },{
            'label': 'VIP',
            'value': 'VIP'
        },
    ]

}
