import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
// sections
// mock
import palette from 'src/utils/palette';
import ScanLocationModel from 'src/api/scan-location';
import CustomTable from "../components/reusable/CustomTable"
import { message, Tooltip } from 'antd';
import ScanLocationModal from 'src/sections/@dashboard/scan-location/ScanLocationModal';

export default function ScanLocationPage() {
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [scanLocations, setScanLocations] = useState([]);
    const [isNewRecord, setIsNewRecord] = useState(true)
    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setData(row)
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setData(null);
    };

    const deleteEntry = async () => {
        try {
            let res = await ScanLocationModel.delete(data.id)
            console.log(res)
            if (res.success) {
                message.success("Successfully delete Scan Location")
                fetchScanLocation()
                handleCloseMenu()
                return
            } else {
                message.error("Failed to delete Scan Location")
            }
            throw (res)
        } catch (error) {
            console.log(error)
            message.error('There was error from server')
            return
        }
    }

    const fetchScanLocation = async () => {
        try {
            let res = await ScanLocationModel.getAll();
            let formattedRes = res.map(obj => {
                return {
                    ...obj,
                    uppercaseName: obj?.name?.toUpperCase()
                }
            })
            setScanLocations(formattedRes)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchScanLocation();
    }, [])

    const columns = [
        {
            id: 'id', label: 'ID', filter: true,
        },
        {
            id: 'name', label: 'Name', filter: true,
        },
        {
            id: 'uuid', label: 'UUID', filter: true,
        },
        {
            id: 'actions', label: "", render: (row, key) => {
                return <>
                    <Tooltip title="Edit">
                        <IconButton size="large" color="inherit" onClick={(event) => {
                            setData(row);
                            setOpenModal(true);
                            setIsNewRecord(false)
                        }}>
                            <Iconify icon={'eva:edit-fill'} sx={{}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Scanner">
                        <Link to={'/scan-location/' + row.id}>
                            <IconButton size="large" color="inherit">
                                <Iconify icon={'ri:qr-scan-line'} sx={{ color: "black" }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Delete">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ color: "red" }} />
                        </IconButton>
                    </Tooltip> */}
                </>
            }
        },
    ];

    return (
        <>
            <Helmet>
                <title> Scan Location | NCSC 2023</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Scan Location
                    </Typography>
                    <Button style={{ backgroundColor: palette.ColorB }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                        setIsNewRecord(true)
                        setOpenModal(true)
                    }}>
                        New Scan Location
                    </Button>
                </Stack>
                <Card>
                    <CustomTable
                        data={scanLocations}
                        columns={columns}
                        searchText="Scan Locations"
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 200,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Typography>Are you sure you want to delete this entry?</Typography>
                <div className='d-flex justify-content-between'>
                    <Button onClick={handleCloseMenu}>No</Button>
                    <Button onClick={deleteEntry}>Yes</Button>
                </div>
            </Popover>

            <ScanLocationModal
                open={openModal}
                isNewRecord={isNewRecord}
                onClose={(isRefresh) => {
                    setOpenModal(false);
                    setData(null);
                    setIsNewRecord(true)
                    if (isRefresh) fetchScanLocation();
                }}
                data={data}
            />
        </>
    );
}
