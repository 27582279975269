import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    IconButton,
    Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
// mock
import palette from 'src/utils/palette';
import SessionModal from 'src/sections/@dashboard/session/SessionModal';
import SessionModel from 'src/api/session';
import CustomTable from "../components/reusable/CustomTable"
import { Image, message, Tooltip } from 'antd';
import SessionBookmarkModal from 'src/sections/@dashboard/session/SessionBookmarkModal';
import { Link } from 'react-router-dom';
import RegisteredUserModal from 'src/components/custom/RegisteredUserModal';
import SessionUser from 'src/api/sessionuser';
import { forEach } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useChart } from 'src/components/chart';
import moment from 'moment';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const SessionIds = {
    "Business Process": 1,
    "Technology": 2,
    "Organization": 3,
}

export default function SessionPageV2() {
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [session, setSession] = useState([]);
    const [isNewRecord, setIsNewRecord] = useState(true)
    const [showBookmarkList, setShowBookmarkList] = useState(false)
    const [selectedSession, setSelectedSession] = useState(null)
    const [users, setUsers] = useState([]);
    const [modalTitle, setModalTitle] = useState(null)
    //const [barChartData, setBarChartData] = useState({ label: [], series: [] });
    //const [lineChartData, setLineChartData] = useState({ label: [], series: [] });


    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setData(row)
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setData(null);
    };

    const deleteEntry = async () => {
        try {
            let res = await SessionModel.delete(data.id)
            console.log(res)
            if (res.success) {
                message.success("Successfully delete session")
                InitializeData()
                handleCloseMenu()
                return
            } else {
                message.error("Failed to delete session")
            }
            throw (res)
        } catch (error) {
            console.log(error)
            message.error('There was error from server')
            return
        }
    }

    const InitializeData = async () => {
        let res = await SessionModel.getAll();
        setSession(res)
    }

    const getUsersBySession = async (id) => {
        let res = await SessionUser.getUsersBySessionId(id);
        setUsers(res)
    }

    useEffect(() => {
        InitializeData();
    }, [])

    const columns = [
        {
            id: 'id', label: 'ID', filter: true,
        },
        {
            id: 'title', label: 'Title', filter: true,
        },
        {
            id: 'speaker', label: 'Speaker', filter: true,
        },
        /* {
            id: 'start_time', label: 'Start Time', filter: true, render: row => {
                return <>
                    {row?.start_time ? `${dayjs(row.start_time).format('DD MMMM YYYY HH:mm')}` : '-'}
                </>
            }
        },
        {
            id: 'end_time', label: 'End Time', filter: true, render: row => {
                return <>
                    {row?.end_time ? `${dayjs(row.end_time).format('DD MMMM YYYY HH:mm')}` : '-'}
                </>
            }
        }, */
        {
            id: 'actions', label: "", render: (row, key) => {
                return <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                    <Tooltip title="See all registered users">
                        <Link to={'/session_user/'+row.id}>
                            <IconButton size="large" color="inherit">
                                <Iconify icon={'mdi:users'} sx={{ color: "black" }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Scanner">
                        <Link to={'/qr-kiosk/' + row.id}>
                            <IconButton size="large" color="inherit">
                                <Iconify icon={'ri:qr-scan-line'} sx={{ color: "black" }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton size="large" color="inherit" onClick={(event) => {
                            setData(row);
                            setOpenModal(true);
                            setIsNewRecord(false)
                        }}>
                            <Iconify icon={'eva:edit-fill'} sx={{}} />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="List Bookmark">
                        <IconButton size="large" color="inherit" onClick={(event) => {
                            setData(row);
                            setShowBookmarkList(true)
                        }}>
                            <Iconify icon={'material-symbols:bookmark-outline'} sx={{ color: "orange" }} />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Delete">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ color: "red" }} />
                        </IconButton>
                    </Tooltip>
                </div>
            }
        },
    ];

    /* const barOptions = useChart({
        plotOptions: { bar: { columnWidth: '16%' } },
        //fill: { type: chartData.map((i) => i.fill) },
        labels: barChartData?.label,
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} User(s) Registered`;
                    }
                    return y;
                },
            },

        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return value.toFixed(0)
                },
            }
        },
        title: {
            text: 'Registered user(s) in Coaching Clinic',
            floating: true,
            //offsetY: 230,
            align: 'center',
            style: {
                color: '#444'
            }
        }
    });

    const lineOptions = useChart({
        plotOptions: { line: { columnWidth: '16%' } },
        //fill: { type: chartData.map((i) => i.fill) },
        xaxis: {
            categories: lineChartData?.label,
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} User(s) Registered`;
                    }
                    return y;
                },
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return value.toFixed(0)
                },
            }
        },
        title: {
            text: 'Registered user(s) in last 7 days',
            floating: true,
            //offsetY: 230,
            align: 'left',
            style: {
                color: '#444'
            }
        },
        stroke: {
            curve: 'smooth'
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: false,
            offsetY: 25,
            //offsetX: -5,
            fontSize: '10rem',
        },
    }); */

    return (
        <>
            <Helmet>
                <title> Coaching Clinic | NCSC</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Coaching Clinic
                    </Typography>
                    <Button style={{ backgroundColor: palette.ColorB }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                        setIsNewRecord(true)
                        setOpenModal(true)
                    }}>
                        New Coaching Clinic
                    </Button>
                </Stack>

                <Card>
                    <CustomTable
                        data={session}
                        columns={columns}
                        searchText="Coaching Clinic"
                    />
                </Card>

            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 200,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {/* <MenuItem onClick={() => setOpenModal(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
                <Typography>Are you sure you want to delete this entry?</Typography>
                <div className='d-flex justify-content-between'>
                    <Button onClick={handleCloseMenu}>No</Button>
                    <Button onClick={deleteEntry}>Yes</Button>
                </div>
            </Popover>

            <SessionModal
                open={openModal}
                isNewRecord={isNewRecord}
                onClose={(isRefresh) => {
                    setOpenModal(false);
                    setData(null);
                    setIsNewRecord(true)
                    if (isRefresh) InitializeData();
                }}
                data={data}
            />
            {/* <RegisteredUserModal userData={users} onHide={() => setUsers([])} modalTitle={modalTitle} /> */}
        </>
    );
}
