import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    IconButton,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
// mock
import palette from 'src/utils/palette';
import SessionModal from 'src/sections/@dashboard/session/SessionModal';
import SessionModel from 'src/api/session';
import CustomTable from "../components/reusable/CustomTable"
import { Image, message, Tooltip } from 'antd';
import SessionBookmarkModal from 'src/sections/@dashboard/session/SessionBookmarkModal';
import { Link, useParams } from 'react-router-dom';
import SessionUserModel from 'src/api/sessionuser';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import UserSessionDetailModal from 'src/components/custom/UserSessionDetailModal';
import ScanLocation from 'src/api/scan-location';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function ScannedUserPage() {
    const { id } = useParams();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('all');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setData(row)
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setData(null);
    };

    const deleteEntry = async () => {
        try {
            let res = await SessionModel.delete(data.id)
            console.log(res)
            if (res.success) {
                message.success("Successfully delete session")
                fetchSessionUser()
                handleCloseMenu()
                return
            } else {
                message.error("Failed to delete session")
            }
            throw (res)
        } catch (error) {
            console.log(error)
            message.error('There was error from server')
            return
        }
    }

    const fetchSessionUser = async () => {
        try {
            let res = await ScanLocation.getUsers();
            let formatted = res.map((row, index) => (
                {
                    ...row,
                    selected_time: moment(row.selected_time).format("DD-MM-YYYY HH:mm"),
                    phone_num: row.phone_num ? "+" + row.phone_num : "",
                    created_at: moment(row.created_at).format("DD-MM-YYYY HH:mm"),
                    session_checked_in: row.session_checked_in ? "Yes" : "No",
                    no : index + 1
                }
            ))
            console.log("RESU", res)
            setUsers(res)
            setFilteredUsers(formatted)
        } catch (error) {
            console.log(error)
        }
    }

    const limitString = (text, limit) => {
        let substrings = text.split(/\s+/)
        return <>{substrings.slice(0, limit).join(" ")}{substrings.length > 5 ? "..." : ""}
        </>
    }

    useEffect(() => {
        fetchSessionUser();
    }, [])

    const columns = [
        {
            id: 'no', label: 'No.', filter: true,
        },
        {
            id: 'name', label: 'Name', filter: true,
        },

        {
            id: 'created_at', label: 'Scanned At', filter: false, render: row => {
                return <>
                    {moment(row.scanned_at).isSame("2023-10-25", 'day') ? "Day 1" : "Day 2"}
                </>
            }
        },
        /* {
            id: 'actions', label: '', filter: false, render: row => {
                return (
                    <Tooltip title="See details">
                        <IconButton size="large" color="inherit" onClick={() => setUser(row)}>
                            <Iconify icon={'bx:message-alt-detail'} sx={{ color: "black" }} />
                        </IconButton>
                    </Tooltip>
                )
            }
        }, */
    ];

    const csvHeaders = [
        {
            key: 'id', label: 'ID',
        },
        {
            key: 'full_name', label: 'Name',
        },
        {
            key: 'company', label: 'Company',
        },
        {
            key: 'job_title', label: 'Job Title',
        },
        {
            key: 'industry', label: 'Industry',
        },
        {
            key: 'selected_time', label: 'Session Time',
        },
        {
            key: 'phone_num', label: 'Phone #',
        },
        {
            key: 'current_issue', label: 'Current Issue',
        },
        {
            key: 'done_things', label: 'Things Has Been Done',
        },
        {
            key: 'expectation', label: 'Expectation',
        },
        {
            key: 'session_checked_in', label: 'Checked-in',
        },
        {
            key: 'created_at', label: 'Registered At',
        },
    ]

    useEffect(() => {
        filterUsers(filter)
      }, [filter])

      const filterUsers = (filter) => {
        switch (filter) {
          case 'all': setFilteredUsers(users.map((obj,key)=>{
              return {
                  ...obj, no : (key + 1)
              }
          }))
            return;
          case 'day1': setFilteredUsers(users.filter(el => moment(el.scanned_at).isSame("2023-10-25", 'day')).map((obj,key)=>{
              return {
                  ...obj, no : (key + 1)
              }
          }))
            return;
          case 'day2': setFilteredUsers(users.filter(el => moment(el.scanned_at).isSame("2023-10-26", 'day')).map((obj,key)=>{
              return {
                  ...obj, no : (key + 1)
              }
          }))
            return;
        }
      }

    return (
        <>
            <Helmet>
                <title> Scanned Users | NCSC</title>
            </Helmet>

            <Container style={{ maxWidth: '90%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Scanned Users
                    </Typography>
                    {/* <Button style={{ backgroundColor: palette.ColorB, color: 'white' }} variant="contained" startIcon={<Iconify icon={"bi:filetype-csv"} />}>
                        <CSVLink
                            data={users}
                            headers={csvHeaders}
                            filename={"scanned.csv"}
                            target="_blank"
                            style={{ color: 'white', textDecoration: 'none' }}
                        >
                            Download as CSV
                        </CSVLink>
                    </Button> */}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
                    <FormGroup row>
                        <FormControlLabel control={<Switch checked={filter == "all"} onChange={() => setFilter("all")} />} label={"Show all"} />
                        <FormControlLabel control={<Switch checked={filter == "day1"} onChange={() => setFilter("day1")} />} label={"Show day 1 visitors"} />
                        <FormControlLabel control={<Switch checked={filter == "day2"} onChange={() => setFilter("day2")} />} label={"Show day 2 visitors"} />
                        {/*<FormControlLabel control={<Switch checked={filter == "both"} onChange={() => setFilter("both")} />} label={"Show both days visitors"} disabled/>*/}
                    </FormGroup>
                </Stack>
                <Card>
                    <CustomTable
                        data={filteredUsers}
                        columns={columns}
                        searchText="users"
                    />
                </Card>
                <UserSessionDetailModal data={user} onHide={() => setUser(null)} />
            </Container>
        </>
    );
}
