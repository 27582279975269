import { Helmet } from 'react-helmet-async';
import { filter, isNull, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogContent,
  DialogActions,
  AlertTitle,
  Alert
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import moment from "moment";
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import { QrReader } from 'react-qr-reader';
import { message } from 'antd';
import dayjs from 'dayjs'
import UserTagModal from "../components/custom/UserTagModal";
import Portal from 'react-overlays/Portal'
import UserTagModalV2 from "../components/custom/UserTagModalV2";
import SignupModal from 'src/components/custom/SignupModal';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import logo from "../assets/logo.png"
import Lottie from "lottie-react";
import LottiePrinterAnimation from "../assets/lottie_printer_1.json"
import LottieDoneAnimation from "../assets/lottie_check.json"
import LottieErrorAnimation from "../assets/lottie_error.json"
import PrintingModal from "../components/custom/PrintingModal";
import SuccessModal from "../components/custom/SuccessModal";
import ErrorModal from "../components/custom/ErrorModal";

export default function QRScannerFull() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false)
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false)
  const NOTFOUND = 0,
    SUCCESS = 1,
    ERROR = -1

  const FC_USER_MODE = 'user',
    FC_ENVIRONTMENT = 'environment'

  const [scanStatus, setScanStatus] = useState(0)
  const [scanMessage, setScanMessage] = useState(null)
  const [resultScan, setResultScan] = useState()
  const [facingMode, setFacingMode] = useState(FC_USER_MODE)

  const [qrData, setQRData] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const [lottieLoading, setLottieLoading] = useState(true)

  const key = '7a5cf0a0048ff7956f1020e5811ad6561c97e02c8bce9a8201fb5284ede9bbcc'

  const navigate = useNavigate()

  const handleMessage = () => {
    let obj = {}
    if (scanStatus === SUCCESS) {
      obj = {
        title: 'Success',
        color: 'green',
        message: 'Checkin successfully'
      }
    } else if (scanStatus === NOTFOUND) {
      obj = {
        title: 'Not Found',
        color: 'red',
        message: 'User not found'
      }
    } else {
      obj = {
        title: 'Error',
        color: 'red',
        message: "Invalid QR"
      }
    }
    return obj
  }
  // const handleResult = async (qrData) => {
  //   try {
  //     console.log("SENDING", qrData.text)
  //     let result = await User.scanAction(qrData.text)
  //     console.log('isi resultnya', result)
  //     if (result?.name) {
  //
  //       setScanStatus(SUCCESS)
  //     } else {
  //       setScanStatus(NOTFOUND)
  //     }
  //     setResultScan(result)
  //
  //   } catch (e) {
  //     console.log(e)
  //     setScanStatus(ERROR)
  //   }
  // }

  const decryptData = (data) => {
    try {
      console.log(data)
      let deciphered = CryptoJS.Rabbit.decrypt(data, key).toString(CryptoJS.enc.Utf8)
      console.log(deciphered)
      return deciphered.split(";")
    } catch (error) {
      console.log(error)
      setErrorMessage("Error Scanning QR")
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }

  const handleResult = async (qrData) => {
    try {
      if (qrData.length > 10) {

        let deciphered = decryptData(qrData)

        setQRData({
          name: deciphered[1],
          code: qrData,
          companyName: deciphered[2],
          jobTitle: deciphered[3],
          showStatus: 0
        })
      } else {
        let result

        try {
          result = await User.scanAction(qrData.text)
          console.log(result)
        } catch (e) {
          console.log('no')
          /* setOpenDialog(true)
          setScanStatus(ERROR) */
          setErrorMessage("Error Scanning QR")
          setTimeout(() => {
            setErrorMessage(null)
          }, 2000)
        }


        console.log(qrData)
        if (result?.name) {
          // setScanStatus(SUCCESS)
          setQRData({
            name: result.name,
            code: result.uuid,
            uuid: result.uuid,
            companyName: result.affiliate,
            jobTitle: result.job_title,
            showStatus: 0
          })

        } else {
          setErrorMessage("Error Scanning QR")
          setTimeout(() => {
            setErrorMessage(null)
          }, 2000)
          //setScanStatus(NOTFOUND)
        }
      }
      setResultScan(result)

    } catch (e) {
      // setScanStatus(ERROR)
      setErrorMessage("Error Scanning QR")
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }
  const [isFullscreen, setFullscreen] = useState(false)
  const handle = useFullScreenHandle()
  const reportChange = useCallback((state) => {
    console.log(state)
    if (state === true) {
      setFullscreen(true)
    } else if (state === false) {
      setFullscreen(false)
    }
  })

  const handlePrintingDataSent = () => {
    try {
      setLottieLoading(false)
      setTimeout(() => {
        setQRData(null)
      }, 3000)
    } catch (error) {

    }
  }

  let contentRef = useRef(null)
  useEffect(() => {
    console.log('width', contentRef.current ? contentRef.current.offsetWidth : 0);
  }, [contentRef.current]);

  return (
    <>
      <body style={{
        height: "100%",
        overflow: "hidden",
      }}>
        <section
          ref={contentRef}
        >
          <Dialog fullWidth={true} maxWidth={'sm'} onClose={() => {
            setOpenDialog(false)
          }} open={openDialog}>
            <DialogContent dividers>
              <div className='mb-5 text-center'>
                <Iconify sx={{
                  color: handleMessage().color,
                  width: '80px',
                  height: '80px',
                }} icon={scanStatus === SUCCESS ? 'clarity:success-standard-line' : 'clarity:times-circle-solid'} />
                <h3 className='text-center mt-3'>{handleMessage().title}</h3>
                <p>{handleMessage().message}</p>
              </div>
              {
                scanStatus === SUCCESS &&

                <div className='info-qr-content'>
                  <div className='text-row mb-2'>
                    <div className='label-text'>Full Name</div>
                    <div className='filled-text'>
                      {resultScan?.name}
                    </div>
                  </div>

                  <div className='text-row mb-2'>
                    <div className='label-text'>Date of Birth</div>
                    <div className='filled-text'>
                      {dayjs(resultScan?.dob).format('DD/MM/YYYY')}
                    </div>
                  </div>

                  <div className='text-row mb-2'>
                    <div className='label-text'>Gender</div>
                    <div className='filled-text'>
                      {resultScan?.gender}
                    </div>
                  </div>
                  <div className='text-row mb-2'>
                    <div className='label-text'>Email</div>
                    <div className='filled-text'>
                      {resultScan?.email}
                    </div>
                  </div>

                  <div className='text-row mb-2'>
                    <div className='label-text'>Phone Number</div>
                    <div className='filled-text'>
                      {resultScan?.phone_num}
                    </div>
                  </div>
                </div>
              }

              {
                scanStatus === ERROR &&
                <div>
                  <h1>ERROR</h1>
                </div>
              }

            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => {
                setOpenDialog(false)
              }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Helmet>
            <title> QR Scanner | Intellivent Registration Dashboard </title>
          </Helmet>
          {/* <Button onClick={() => {setFacingMode(facingMode === FC_USER_MODE ? FC_ENVIRONTMENT : FC_USER_MODE)}}>
          switch
      </Button> */}

          <FullScreen
            handle={handle}
            onChange={reportChange}>
            <div id="fullscreenElement">
              <QrReader
                onResult={(result, error) => {
                  console.log('handling result', result)
                  if (!!result) {
                    result.text.length > 10 ? handleResult(result.text) : handleResult(result)
                    // setOpenDialog(true)
                  }

                  if (!!error) {

                  }

                }}
                ViewFinder={() => {
                  return (
                    <>
                      {/*<div className={isFullscreen ? 'crosshair-full' : 'crosshair'}></div>*/}
                    </>
                  )
                }}
                constraints={{ facingMode: facingMode }}
                style={{
                  marginTop: "-10vh"
                }}
                videoStyle={{ maxWidth: '100vw', height: "100vh", objectFit: "cover", }}
              />
              <div style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                zIndex: 20,
                top: 0,
                left: 0
              }}
              >
                <div style={{
                  background: "#00000080",
                  height: "15vh",
                  width: "100vw",
                  justifyContent: "center",
                  alignItems: "center"
                }}>

                </div>

                <div
                  className={"d-flex"}
                  style={{
                    background: "#00000080",
                    justifyContent: "center",
                    /* alignItems: "center",
                    textAlign: "center",
                    objectFit: "cover", */
                  }}>
                  <img height="120vh" src={logo} alt="logo-ncsc"></img>
                </div>
                <div style={{
                  background: "#00000080",
                  color: "white",
                  textAlign: "center",
                  fontSize: "1.2rem"
                }}>
                  <br />
                  <h4><b>Welcome to National CyberSecurity Connect 2023 </b> </h4>
                  For checking in, please point your QR Code inside the box area below <br /> <br />
                </div>
                <div
                  className={"d-flex flex-row"}
                  style={{
                    // background : "#00000080",
                    height: "30vh",
                    width: "100vw"
                  }}>
                  <div style={{
                    flex: 1,
                    background: "#00000080",
                  }}>

                  </div>
                  <div style={{
                    height: "100%",
                    width: "30vh",
                    border: "3px solid black"
                  }}>

                  </div>
                  <div style={{
                    flex: 1,
                    background: "#00000080",
                  }}>

                  </div>
                </div>
                <div
                  className={"d-flex flex-column align-items-center justify-content-start"}
                  style={{
                    background: "#00000080",
                    height: "35vh",
                    width: "100vw",
                    paddingRight: "2vw",
                    paddingLeft: "2vw",
                    paddingTop: 20
                  }}>





                  {/* {
                  !isFullscreen &&
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      navigate("/qr-kiosk")
                    }}>Full Screen Mode</Button>

                } */}

                  {isFullscreen &&
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <div style={{ marginTop: "1em", color: "white" }}>Don't have an account?</div>
                      <Button variant={"contained"} onClick={() => setIsOpenSignupModal(true)}>
                        Click here to sign up
                      </Button>
                    </div>
                  }
                </div>


              </div>

              <SignupModal
                isOpen={isOpenSignupModal}
                onClose={() => setIsOpenSignupModal(false)}
                setUuid={uuid => handleResult(uuid)}
              />

              <UserTagModal
                data={qrData}
                onClose={() => {
                  setTimeout(() => {
                    setLottieLoading(false)
                  }, 3000)
                }}
                autoPrint={true}
              //useCallback={handlePrintingDataSent}
              />

              <PrintingModal
                onClose={() => {
                  setQRData(null)
                  setLottieLoading(true)
                }}
                lottieLoading={lottieLoading}
                scannedUser={qrData}
              />

              <ErrorModal
                onClose={() => {
                  setQRData(null)
                  setErrorMessage(null)
                }}
                errorMessage={errorMessage}
              />

              {/* <SuccessModal
            onClose={() => {
              setQRData(null)
            }}
            lottieLoading={lottieLoading}
            scannedUser={qrData}
          />   */}

            </div>
          </FullScreen>

        </section>
      </body>

      {/*<UserTagModal*/}
      {/*  data={qrData}*/}
      {/*  onClose={() => {*/}
      {/*    setQRData(null)*/}
      {/*  }}*/}
      {/*/>*/}

    </>
  );
}
