import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  Modal,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
//import USERLIST from '../_mock/admin';
import moment from 'moment';
import { Box } from '@mui/system';
import BoothModal from 'src/sections/@dashboard/booth/BoothModal';
import Booth from 'src/api/booth';
import CustomTable from "../components/reusable/CustomTable";
import { Image, Tooltip } from 'antd';

// ----------------------------------------------------------------------

export default function BoothPage() {
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const [booths, setBooths] = useState([]);

  const [isNewRecord, setIsNewRecord] = useState(false)

  const handleOpenMenu = (event, row) => {
    setData(row)
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setData(null)
    setIsNewRecord(true)
  };

  const handleCloseModal = (refresh) => {
    setOpenModal(false);
    setData(null);
    if (refresh) fetchBooths();
  }

  const fetchBooths = async () => {
    try {
      let res = await Booth.getAll()
      console.log("booths", res)
      setBooths(res);
    } catch (error) {
      console.log(error)
    }
  }

  const deleteEntry = async () => {
    try {
      let res = await Booth.delete(data.id)
      console.log(res)
      if (res.success) {
        fetchBooths()
        handleCloseMenu()
        return
      }
      throw (res)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      id: 'id', label: "ID"
    },
    {
      id: 'image_url', label: 'Image', filter: true, render: row => {
        return <>
          <Image style={{ width: 120, height: 80 }} src={row?.image_url}></Image>
        </>
      }
    },
    {
      id: 'name', label: 'Name', filter: true,
    },
    {
      id: 'email', label: 'Email', filter: true,
    },
    {
      id: 'created_at', label: 'Created at',
      render: (row) => {
        return row.created_at ? moment(row.created_at).format("DD MMM YYYY HH:mm") : "-"
      }
    },
    {
      id: 'actions', label: "", render: (row, key) => {
        return <>
          <Tooltip title="Edit">
            <IconButton size="large" color="inherit" onClick={(event) => {
              setData(row);
              setOpenModal(true);
              setIsNewRecord(false)
            }}>
              <Iconify icon={'eva:edit-fill'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </>
      }
    },
  ];

  useEffect(() => {
    fetchBooths();
  }, [])

  return (
    <>
      <Helmet>
        <title> Booth | NCSC 2023</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Booth
          </Typography>
          <Button onClick={() => {
            setIsNewRecord(true);
            setOpenModal(true)
          }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Booth
          </Button>
        </Stack>

        <Card>
          <Card>
            <CustomTable
              data={booths}
              columns={columns}
              searchText="booths"
            />
          </Card>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => setOpenModal(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
        <Typography>Are you sure you want to delete this entry?</Typography>
        <div className='d-flex justify-content-between'>
          <Button onClick={handleCloseMenu}>No</Button>
          <Button onClick={deleteEntry}>Yes</Button>
        </div>
      </Popover>

      <BoothModal
        isNewRecord={isNewRecord}
        open={openModal}
        onClose={handleCloseModal}
        data={data}
      />

    </>
  );
}
