import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, CardHeader } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  // AppTrafficBySite,
  // AppWidgetSummary,
  // AppCurrentSubject,
  // AppConversionRates,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import User from 'src/api/user';
import dayjs from 'dayjs'
import moment from "moment"
import _ from "lodash"
import ReactApexChart from 'react-apexcharts';
import { useChart } from 'src/components/chart';
import SessionModel from 'src/api/session';
import SessionUser from 'src/api/sessionuser';
import ScanLocation from 'src/api/scan-location';
// ----------------------------------------------------------------------
const SessionIds = ["Business Process", "Technology", "Organization"]

export default function DashboardAppPage() {
  const theme = useTheme();
  const [ageData, setAgeData] = useState([])
  const [genderData, setGenderData] = useState([])
  const [registeredUserData, setRegisteredUserData] = useState([])
  const [industriesData, setIndustriesData] = useState([])

  const [userTotal, setUserTotal] = useState(0)
  const [todayTotal, setTodayTotal] = useState(0)
  const [lastWeekTotal, setLastWeekTotal] = useState(0)
  const [scanned, setScanned] = useState(0)
  const [registeredDateList, setRegisteredDateList] = useState([])
  const [barChartData, setBarChartData] = useState({ label: [], series: [] });
  const [lineChartData, setLineChartData] = useState({ label: [], series: [] });
  const [lineChartDay1Data, setLineChartDay1Data] = useState({ date: [], data: [] });
  const [lineChartDay2Data, setLineChartDay2Data] = useState({ date: [], data: [] });
  const [lineChartDay1ScanData, setLineChartDay1ScanData] = useState({ date: [], data: [] });
  const [lineChartDay2ScanData, setLineChartDay2ScanData] = useState({ date: [], data: [] });

  const [lineChartDay1Location1Data, setLineChartDay1Location1Data] = useState({ date: [], data: [] });
  const [lineChartDay1Location2Data, setLineChartDay1Location2Data] = useState({ date: [], data: [] });

  const barOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    //fill: { type: chartData.map((i) => i.fill) },
    labels: barChartData?.label,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} User(s) Registered`;
          }
          return y;
        },
      },

    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0)
        },
      }
    },
  });

  const lineOptions = useChart({
    plotOptions: { line: { columnWidth: '16%' } },
    //fill: { type: chartData.map((i) => i.fill) },
    xaxis: {
      categories: lineChartData?.label,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} User(s) Registered`;
          }
          return y;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0)
        },
      }
    },
    stroke: {
      curve: 'smooth'
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: false,
      offsetY: 25,
      //offsetX: -5,
      fontSize: '10rem',
    },
    colors: ['#FF0000', '#0000FF', '#FFFF00'],
  });

  const initializeData = async () => {

    try {


      let res = await User.getAll()
      /* let resScanHistory = await ScanLocation.getUsers()
      let resScan1 = await ScanLocation.getByScanLocationId(1)
      let resScan2 = await ScanLocation.getByScanLocationId(2) */

      console.log("RES", res)
      setUserTotal(res?.length)
      let genders = [
        {
          label: 'MALE',
          value: res?.filter((v) => {
            return v.gender === 'MALE'
          })?.length
        },
        {
          label: 'FEMALE',
          value: res?.filter((v) => {
            return v.gender === 'FEMALE'
          })?.length
        }
      ];
      setGenderData(genders)

      let usrAge = User.getListAge?.map((v) => {
        let dataAgeUser = res?.filter((vx) => {
          return v.value === vx.age
        })
        return {
          'label': v.label,
          'value': dataAgeUser?.length
        }
      })
      setAgeData(usrAge)

      let indsData = User.getListIndustry?.map((v) => {
        let dataInds = res?.filter((vx) => {
          return v.value === vx.sector
        })
        return {
          'label': v.label,
          'value': dataInds?.length
        }
      })
      
      let filteredIndsData = indsData.filter(obj => obj.value > 0)
      let sortedIndsData = [].concat(filteredIndsData)
      .sort((a, b) => a.value < b.value ? 1 : -1);
      setIndustriesData(sortedIndsData)

      let printed = res?.filter(el => !!el.check_in_time).length
      console.log("PRINTED", printed)
      setScanned(printed)

      const TOTAL_DAYS = 16
      let arrDate = []
      let arrRegist = []

      let lastWeekTotal = 0
      let totalTotal = 0

      let startingDate = moment("10/10/2023", 'MM/DD/YYYY').toDate()
      let endDate = moment("10/27/2023", 'MM/DD/YYYY').toDate()
      let currentDate = startingDate

      while (moment(currentDate).isBefore(endDate)) {
        // let dates = moment().subtract(i, 'day').format('MM/DD/YYYY')

        arrDate.push(moment(currentDate).add(8, 'hours').format("MM/DD/YYYY HH:mm:ss"))

        let formattedCurrent = moment(currentDate).format("MM/DD/YYYY")

        let filteredDate = res?.filter((v) => {
          // console.log('created at', moment(v.created_at).format('DD/MM/YYYY'))
          return moment(v.created_at).format('MM/DD/YYYY') === formattedCurrent
        })
        arrRegist.push(filteredDate?.length)
        currentDate = moment(currentDate).add(1,'days').toDate()

      }

      for (let i = TOTAL_DAYS; i >= 0; i--) {
        // let dates = moment('2023-09-27 23:59', 'YYYY-MM-DD HH:mm').subtract(i, 'day').format('MM/DD/YYYY')
        let dates = moment().subtract(i, 'day').format('MM/DD/YYYY')
        //arrDate.push(moment(dates).add(8, 'hours').format("MM/DD/YYYY HH:mm:ss"))

        let filteredDate = res?.filter((v) => {
          // console.log('created at', moment(v.created_at).format('DD/MM/YYYY'))
          return moment(v.created_at).format('MM/DD/YYYY') === dates
        })
        // arrRegist.push(filteredDate?.length)

        totalTotal += filteredDate?.length ? filteredDate.length : 0

        if (i < 7) {
          lastWeekTotal += filteredDate?.length ? filteredDate.length : 0
        } else {
          console.log("ada nih ", i, filteredDate)
        }

        if (i === 0) {
          setTodayTotal(filteredDate?.length ? filteredDate.length : 0)
        }

      }

      let tempHours1 = [], tempHours2 = [], tempDay1 = [], tempDay2 = []
      for (let i = 0; i<= 10; i++){
        let temp1 = moment('2023-10-25 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        let temp2 = moment('2023-10-26 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        tempHours1.push(temp1.format('YYYY-MM-DD HH:mm'))
        tempHours2.push(temp2.format('YYYY-MM-DD HH:mm'))

        tempDay1.push(res.filter(el => moment(el.check_in_time).isBetween(temp1, moment(temp1).add(1, 'h'))).length)
        tempDay2.push(res.filter(el => moment(el.check_in_time).isBetween(temp2, moment(temp2).add(1, 'h'))).length)
      }
      console.log('TEMP', tempHours1, tempHours2, tempDay1, tempDay2)
      setLineChartDay1Data({date: tempHours1, data: tempDay1})
      setLineChartDay2Data({date: tempHours2, data: tempDay2})

      /* let tempHoursScan1 = [], tempHoursScan2 = [],tempDay1Scan = [], tempDay2Scan = []
      for (let i = 0; i<= 10; i++){
        let temp1 = moment('2023-10-25 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        let temp2 = moment('2023-10-26 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        tempHoursScan1.push(temp1.format('YYYY-MM-DD HH:mm'))
        tempHoursScan2.push(temp2.format('YYYY-MM-DD HH:mm'))

        tempDay1Scan.push(resScanHistory.filter(el => moment(el.scanned_at).isBetween(temp1, moment(temp1).add(1, 'h'))).length)
        tempDay2Scan.push(resScanHistory.filter(el => moment(el.scanned_at).isBetween(temp2, moment(temp2).add(1, 'h'))).length)
      }
      console.log('TEMP', tempHours1, tempHours2, tempDay1, tempDay2)
      setLineChartDay1ScanData({date: tempHours1, data: tempDay1Scan})
      setLineChartDay2ScanData({date: tempHours2, data: tempDay2Scan}) */

      /* let tempDay1Location1 = [], tempDay1Location2 = []
      for (let i = 0; i<= 10; i++){
        let temp1 = moment('2023-10-25 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        let temp2 = moment('2023-10-26 07:00', 'YYYY-MM-DD HH:mm').utcOffset(14).add(i, 'h')
        tempHours1.push(temp1.format('YYYY-MM-DD HH:mm'))
        tempHours2.push(temp2.format('YYYY-MM-DD HH:mm'))

        tempDay1Location1.push(resScan1.filter(el => moment(el.scanned_at).isBetween(temp1, moment(temp1).add(1, 'h'))).length)
        tempDay1Location2.push(resScan2.filter(el => moment(el.scanned_at).isBetween(temp2, moment(temp2).add(1, 'h'))).length)
      }
      setLineChartDay1Location1Data({date: tempHours1, data: tempDay1Location1})
      setLineChartDay1Location2Data({date: tempHours1, data: tempDay1Location2}) */

      setLastWeekTotal(lastWeekTotal)
      setRegisteredUserData(arrRegist)
      setRegisteredDateList(arrDate)
      // // setUserTotal(totalTotal)
      console.log("TT", totalTotal)
      console.log("ARRDATE", arrDate)
    } catch (e) {
      console.log(e)

    }
  }

  const InitializeCoachingClinicData = async () => {
    let res = await SessionUser.getAll();
    console.log("RES", res)

    // Bikin seriesnya dulu
    let tempBarChartSeries = []
    for (let i = 1; i <= 3; i++) {
      tempBarChartSeries.push(res.filter(el => el.session_id === i).length)
    }
    tempBarChartSeries = [{ name: "Registered User", data: tempBarChartSeries }]

    // Bikin labelnya
    let tempBarChartLabel = ["Business Process", "Technology", "Organization"]

    // Data bar chart done
    setBarChartData({ label: tempBarChartLabel, series: tempBarChartSeries });

    let tempLineChartSeries = []
    let tempLineChartLabel = []
    let tempDataArray = []
    for (let j = 1; j <= 3; j++) { // 3 Sessions
      let tempByDate = [] 
      for (let i = 6; i >= 0; i--) { // Last 7 days
        let date = moment().subtract(i, 'days');
        let temp = res.filter(el => moment(el.created_at).isSame(date, 'days') && el.session_id === j)
        tempByDate.push(temp.length)
        tempLineChartLabel.push(moment(date).format("DD-MM-YYYY"))
      }
      tempDataArray.push(tempByDate)
    }

    tempDataArray.forEach((element, index) => {
      tempLineChartSeries.push({ name: SessionIds[index], data: element })
    });

    setLineChartData({ label: tempLineChartLabel, series: tempLineChartSeries })
  }

  useEffect(() => {
    initializeData()
    /* InitializeCoachingClinicData() */
  }, [])

  return (
    <>
      <Helmet>
        <title> Dashboard | NCSC 2023 </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <AppWidgetSummary title="Users Registered" total={userTotal} color="info" icon={'ant-design:user-outlined'} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <AppWidgetSummary title="Total Last Week" total={lastWeekTotal} color="error" icon={'ant-design:calendar-outlined'} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <AppWidgetSummary title="Last Day Registration" total={todayTotal} color="warning" icon={'ant-design:stock-outlined'} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <AppWidgetSummary title="Total Printed" total={scanned} color="success" icon={'ant-design:printer-outlined'} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppWebsiteVisits
              title="Day 1 Print Data"
              subheader={`25-10-2023`}
              chartLabels={lineChartDay1Data.date}
              chartData={[
                {
                  name: '',
                  type: 'area',
                  fill: 'gradient',
                  data: lineChartDay1Data.data,
                },
              ]}
              tooltip="User Card(s) printed"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppWebsiteVisits
              title="Day 2 Print Data"
              subheader={`26-10-2023`}
              chartLabels={lineChartDay2Data.date}
              chartData={[
                {
                  name: '',
                  type: 'area',
                  fill: 'gradient',
                  data: lineChartDay2Data.data,
                },
              ]}
              tooltip="User Card(s) printed"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppWebsiteVisits
              title="Last 2 Weeks Registration"
              subheader={`10/10/2023 - 26/10/2023`}
              chartLabels={registeredDateList}
              chartData={[
                {
                  name: '',
                  type: 'area',
                  fill: 'gradient',
                  data: registeredUserData,
                },
              ]}
            />
          </Grid>
          
          {/* <Grid item xs={12} md={6} lg={6}>
            <AppWebsiteVisits
              title="Day 1 Scan Data"
              subheader={`25-10-2023`}
              chartLabels={lineChartDay1ScanData.date}
              chartData={[
                {
                  name: '',
                  type: 'area',
                  fill: 'gradient',
                  data: lineChartDay1ScanData.data,
                },
              ]}
              tooltip="User(s) scanned"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppWebsiteVisits
              title="Day 2 Scan Data"
              subheader={`26-10-2023`}
              chartLabels={lineChartDay2ScanData.date}
              chartData={[
                {
                  name: '',
                  type: 'area',
                  fill: 'gradient',
                  data: lineChartDay2ScanData.data,
                },
              ]}
              tooltip="User(s) scanned"
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="Genders"
              chartData={genderData}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.error.main,
                theme.palette.grey[400],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="Age"
              chartData={ageData}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.success.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppCurrentVisits
              customClass="chart-context-content"
              title="Industries"
              chartData={industriesData}
            // chartColors={[
            //   theme.palette.primary.main,
            //   theme.palette.error.main,
            //   theme.palette.grey[400],
            // ]}
            />
          </Grid>


        </Grid>
      </Container>
    </>
  );
}
